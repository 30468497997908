@import "./index.scss";
@import "node_modules/bootstrap/scss/bootstrap";

body {
	font-family: "Playfair Display", serif;
	background-color: $gunmetal;
	color: $color;
}

h2,
h3,
h4,
h5,
h6 {
	font-family: "Playfair Display", serif;
}

body,
html {
	height: 100%;
}

h1 {
	font-family: "Satisfy", cursive;
	font-size: 3em;
}

.hero-image {
	@include flexCenter();
	@include button();
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("./bg.jpg");
	height: 50vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	border-bottom: 5px #1a2223 solid;
	
	.image {
		margin-left: -20%;
		margin-top: 1.5%;
		img {
			width: 30vw;
			position: left;
		}
	}
}

.text {
	@include button();
	@include borderRadiusContainers();
	background-color: rgba($color: $blue, $alpha: 0.7);
	margin-left: 5%;
	margin-top: 8%;
	text-align: center;
	color: $gunmetal;
	max-height: 40%;
	padding: 1%;

	button {
		width: 111%;
		margin-top: 4%;
		margin-left: -5%;
		font-size: 1.2em;
	}

	.icons {
		color: $gunmetal;
		font-size: 30px;
		&:hover {
			color: #1a2223;
		}
		&:active {
			color: $gunmetal;
		}
	}
}

footer {
	background-color: #20292b;
	position: fixed;
	text-align: center;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	padding: 0.5%;
	border-top: 2px solid #1a2223;
	box-shadow: 2px 2px #1a2223;
	z-index: 900;
	.right {
		margin-left: 5%;
	}
}

.college {
	margin-top: 2%;
	h1 {
		margin-left: 25%;
		color: $yellow;
	}
	b {
		color: $yellow;
	}
	div {
		@include flexCenter();
		.cImage {
			margin-right: 5%;
			margin-bottom: 3%;
			margin-top: 2%;
		}
		.cText {
			flex-direction: column;
		}
	}
}

center {
	color: $blue;
	margin-top: 1%;
}

.projects {
	margin: 2% 1%;
	margin-bottom: 5%;
	@include button;
	@include flexBetween;
	flex-wrap: wrap;
	.project {
		margin-right: 2.5%;
		margin-bottom: 1%;
		.card {
			background-color: #20292b;
			height: 100%;
			.carousel-control-next:hover,
			.carousel-control-prev:hover {
				background-color: rgba($color: $gunmetal, $alpha: 0.7);
			}
			.carousel-item {
				position: relative;

				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.5);
					opacity: 0;
					transition: opacity 0.3s ease, box-shadow 0.3s ease;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

					&:hover {
						opacity: 1;
						box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
						cursor: pointer;
					}
				}
			}
			img {
				width: 500px;
				max-height: 990px;
				&:hover {
					cursor: pointer;
				}
			}
			border: 5px solid #1a2223;
			border-radius: 10px;
			.card-title {
				color: $purple;
			}
			.card-body {
				background-color: #20292b;
				color: $color;
				word-wrap: normal;
				max-width: 500px;
			}
			.centerIcons {
				@include flexCenter();
				.icons {
					width: 15%;
					margin: 7%;
				}
				.manyIcons {
					margin: 3%;
				}
			}
		}
	}
}

.modal-content {
	background-color: #20292b;
}

.scrollBtn {
	position: fixed;
	bottom: 50px;
	right: 20px;
	padding: 10px;
	outline: none;
	background-color: $liver;
	color: $color;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	z-index: 100;
	&:hover {
		background-color: $blue;
		color: $gunmetal;
	}
}

.scrollBtnMobile {
	position: fixed;
	bottom: 50px;
	right: 13px;
	// padding: 10px;
	outline: none;
	color: $liver;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	font-size: 30px;
	&:hover {
		color: $blue;
	}
}

.centerChart {
	color: $gunmetal;
	@include flexCenter;
	margin-bottom: 10%;
	.chart-container {
		h1 {
			color: $purple;
			text-align: center;
		}
		h4 {
			color: $purple;
			text-align: center;
		}
	}
}

.VictoryContainer {
	svg {
		width: 1100px !important;
	}
}

@import "./media.scss";
