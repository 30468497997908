// Styles for 1920x1080 and larger screens
@media screen and (min-width: 1920px) {
}

// Styles for 1366x768 and larger screens
@media screen and (min-width: 1366px) {
	// Your styles for 1366x768
}

// Styles for 1440x900 and larger screens
@media screen and (min-width: 1440px) {
	// Your styles for 1440x900
}

// Styles for 1600x900 and larger screens
@media screen and (min-width: 1600px) {
	// Your styles for 1600x900
}

// Styles for 2560x1440 and larger screens
@media screen and (min-width: 2560px) {
	// Your styles for 2560x1440
}

// Styles for other specific screen sizes
@media screen and (min-width: 1536px) {
	// Your styles for 1536x864
}

@media screen and (min-width: 810px) and (max-width: 1100px) {
	.projects {
		display: flex;
		.project {
			width: 45vw;
			.card {
				img {
					width: 44vw;
				}
			}
		}
	}
}

@media screen and (min-width: 550px) and (max-width: 809px) {
	.college {
		.cImage {
			width: 80%;
		}
	}

	.hero-image {
		height: 35vh;
		button {
			margin-top: 10%;
		}
		.image {
			img {
				width: 50vw;
				margin-left: 12%;
				margin-right: 20%;
				margin-top: 5%;
			}
		}
	}

	.projects {
		@include flexCenter;
		flex-direction: column;
		.project {
			width: 95vw;
			.card {
				img {
					width: 94vw;
				}
			}
		}
	}

	.chart-container {
		h4 {
			font-size: 1em;
		}
	}
}

@media screen and (max-width: 549px) {
	.hero-image {
		display: block;
		height: 62vh;
		img {
			margin-left: 20%;
			width: 70vw;
		}
	}
	.chart-container {
		h4 {
			font-size: 1em;
		}
	}
	.text {
		width: 90vw;
		margin: 2%;
		button {
			width: 100%;
			margin-left: 0.1%;
			margin-top: 15%;
		}
	}
}

// Mobile styles for screens up to 360x640
@media screen and (max-width: 360px) {
	.hero-image {
		display: block;
		height: 60vh;
		img {
			margin-left: 30%;
			width: 70vw;
		}
	}
	.text {
		width: 90vw;
		margin: 2%;
		button {
			width: 100%;
			margin-left: 0.1%;
			margin-top: 10%;
		}
	}

	.projects {
		.project {
			width: 98vw;
			.card {
				img {
					width: 95vw;
				}
			}
		}
	}

	.college {
		margin-bottom: 10%;
		h1 {
			text-align: center;
			margin-left: 0%;
			margin-bottom: 1%;
		}
		img {
			width: 70vw;
			margin-left: 15vw;
		}
		div {
			display: block;
		}
		.cText {
			margin: 2%;
		}
	}
	.chart-container {
		h4 {
			font-size: 1em;
		}
	}
}

// Mobile styles for screens between 360x640 and 414x896
@media screen and (min-width: 360px) and (max-width: 414px) {
	.hero-image {
		display: block;
		height: 65vh;
		img {
			margin-left: 30%;
			width: 70vw;
		}
		.text {
			width: 90vw;
			margin: 2%;
			button {
				width: 100%;
				margin-left: 0.1%;
				margin-top: 10%;
			}
		}
	}

	.chart-container {
		h4 {
			font-size: 1em;
		}
	}

	.projects {
		.project {
			width: 98vw;
			.card {
				img {
					width: 95vw;
				}
			}
		}
	}

	.college {
		margin-bottom: 10%;
		h1 {
			text-align: center;
			margin-left: 0%;
			margin-bottom: 1%;
		}
		img {
			width: 70vw;
			margin-left: 15vw;
		}
		div {
			display: block;
		}
		.cText {
			margin: 2%;
		}
	}
}

// Mobile styles for screens between 375x812 and 390x844
@media screen and (min-height: 844px) and (max-height: 895) {
	.text {
		button {
			margin-top: 25%;
		}
	}
	.chart-container {
		h4 {
			font-size: 1em;
		}
	}
}

// Mobile styles for screens between 360x780 and 412x915
@media screen and (min-width: 360px) and (max-width: 412px) {
	.text {
		button {
			margin-top: 25%;
		}
	}

	.college {
		margin-bottom: 25%;
	}
	.chart-container {
		h4 {
			font-size: 1em;
		}
	}
}

// Mobile styles for screens up to 393x873
@media screen and (max-width: 393px) {
	.text {
		button {
			margin-top: 25%;
		}
		margin-bottom: 2%;
	}
	.image {
		img {
			width: 50vw;
			margin-left: 18%;
		}
	}

	.college {
		margin-bottom: 25%;
	}
	.chart-container {
		h4 {
			font-size: 1em;
		}
	}
}
