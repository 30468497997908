//vars
$gunmetal: #273134;
$liver: #725752;
$blue: #7ab4a8;
$purple: #b99ac4;
$yellow: #efe5ac;
$color: #e5e5e5;

//import
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Satisfy&display=swap");

//mixins
@mixin flexCenter {
	display: flex;
	align-content: center;
	justify-content: center;
}

@mixin flexBetween {
	display: flex;
	align-content: space-between;
	justify-content: space-between;
}

@mixin borderRadius {
	border-radius: 5px;
}

@mixin borderRadiusContainers {
	border-radius: 10px;
}

@mixin button {
	button {
		background-color: $liver;
		color: $color;
		border: none;
		padding: 1% 2%;
		cursor: "pointer";
		margin: 2%;
		@include borderRadius();
	}
	// button:hover {
	// 	background-color: $blue-hover;
	// }
	// button:active {
	// 	background-color: $blue-active;
	// }
}
